export default {
  vehicles: [],
  vehicle: {},
  vehiclesGrid: {
    data: [],
    total: 0
  }


}
