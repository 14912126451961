export default {
  forSelect (state) {
    const data = []
    for (const k in state.vehicles) {
      data.push({
        value: state.vehicles[k].id,
        label: `${state.vehicles[k].license_plate || ''} - ${state.vehicles[k].brand || ''}  ${state.vehicles[k].model || ''}`,
        data: state.vehicles[k]
      })
    }
    return data
  }
}
