import Vue from 'vue'

export default {
  SET (state, payload) {
    state.vehicle = Object.assign({}, state.vehicle, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.vehicles = payload
  },
  ////////////////////////////////////
  SET_VEHICLES_GRID (state, payload) {
    state.vehiclesGrid = payload
  },
  STORE (state, payload) {
    state.vehicles.push(payload) //precisa ser assim para manter a reatividade
    state.vehicles.sort((a, b) => { return a.brand.localeCompare(b.brand) }) // Ordena por nome

    state.vehiclesGrid.data.push(payload)
    state.vehiclesGrid.data.sort((a, b) => { return a.brand.localeCompare(b.brand) }) // Ordena por nome
    state.vehiclesGrid.total = state.vehiclesGrid.total + 1
  },

  UPDATE (state, payload) {
    const index = state.vehicles.findIndex((o) => o.id === payload.id)
    Vue.set(state.vehicles, index, payload)
    state.vehicles.sort((a, b) => { return a.brand.localeCompare(b.brand) }) // Ordena por nome

    const idx2 = state.vehiclesGrid.data.findIndex((o) => o.id === payload.id)
    Vue.set(state.vehiclesGrid.data, idx2, payload)
    state.vehiclesGrid.data.sort((a, b) => { return a.brand.localeCompare(b.brand) }) // Ordena por nome
  },


  DELETE (state, payload) {
    const idx1 = state.vehicles.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.vehicles.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.vehiclesGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.vehiclesGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.vehiclesGrid.total = state.vehiclesGrid.total - 1
    }
  }

}
