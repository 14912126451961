import axios from '@/axios.js'

export default {
  fetchAll ({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.vehicles.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/vehicles`)
          .then((response) => {
            commit('REFRESH', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.vehicles)
      }
    })
  },


  //////////////////////////////////

  fetchVehicle ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/vehicle/${payload}/edit`)
        .then((res) => {
          commit('SET', res.data)
          resolve(res)
        })
        .catch((error) => { reject(error) })
    })
  },
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/vehicle`, payload)
        .then((response) => {
          commit('STORE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/vehicle/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/vehicles`, payload)
        .then((response) => {
          commit('SET_VEHICLES_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/vehicle/${payload}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
